import axios from "axios";
import store from "../store/index";

const configHeaders = {
    headers: {
        'content-type': 'application/json',
        'Accept': 'application/json'
    }
};

const authHeaders = {
    headers: {
        'content-type': 'application/json',
        'Authorization': null,
        'Accept': 'application/json'
    }
};

export default class Axios {
    makeCall(method, url, data, authorized) {
        const token = store.state.tkn ? store.state.tkn : sessionStorage.getItem('tkn');
        authHeaders.headers.Authorization = 'Bearer ' + token;

        let axiosParameters = {
            method: method,
            url: process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_AJAX_PREFIX + '/' + url,
            headers: configHeaders,
        };

        if (authorized) {
            axiosParameters = Object.assign(axiosParameters, authHeaders);
        } else {
            axiosParameters.headers = configHeaders;
        }

        if (method !== 'GET' && method !== 'DELETE') {
            axiosParameters.data = data;
        }

        if (method === 'POST') {
            axiosParameters.data['_method'] = 'POST';
        }

        if (method != 'GET') {
            axiosParameters.data['_method'] = method;
            axiosParameters.method = 'POST';
        }

        return new Promise((resolve, reject) => {
            axios(axiosParameters)
                .then(response => {
                    if (response.data.code) {
                        reject(response.data)
                    }
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error.response.data)
                    reject(error.response.data);
                    store.commit('setStateParam', {
                        name: 'snackBar',
                        value: {
                            show: true,
                            text: error.response.data.message ? error.response.data.message : (error.response.data ? error.response.data : '!!'),
                            color: 'error'
                        }
                    });
                })
        })
    }


    makeAuthCall = (form) => {
        let data = {
            'grant_type': 'password',
            'client_id': process.env.VUE_APP_API_CLIENT2,
            'client_secret': process.env.VUE_APP_APP_CLIENT_KEY2,
            'username': form.email,
            'password': form.password,
            'scopes': ''
        };

        return new Promise((resolve, reject) => {
            axios.post(process.env.VUE_APP_API_URL + '/' + 'oauth/token', data, {
                'content-type': 'application/json'
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error.response.data)
                })
        })
    }

    validateUser = () => {
        const token = store.state.tkn ? store.state.tkn : sessionStorage.getItem('tkn');
        authHeaders.headers.Authorization = 'Bearer ' + token;
        axios.post(process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_AJAX_PREFIX + '/validate-customer',
            {},
            authHeaders
        )
            .then(response => {
                store.commit('setStateParam', {
                    name: 'user',
                    value: response.data
                });
            }).catch(() => {
            sessionStorage.removeItem('tkn');
            store.commit('setStateParam', {
                name: 'tkn',
                value: null
            });
            store.commit('setStateParam', {
                name: 'user',
                value: null
            });
        })
    }
}