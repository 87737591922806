<template>
  <v-app>
    <v-container fluid class="pa-0 pb-0 position-relative">
      <TopBar v-if="$vuetify.breakpoint.mdAndUp"/>
      <TopBarMobile v-else/>
      <v-img :src="prepareImageUrl(randomBanner.file)" class="cursor-pointer background-image"
             @click="makeClick(randomBanner)" v-if="randomBanner && randomBanner.yt_player===0"/>
        <MainContainer/>
      <BottomBar/>
      <SnackBar/>
    </v-container>
    <LoaderOverlay/>
    <footer>
      <cookie-law theme="dark-lime" buttonText="OK">
        <div slot="message">
          <b>Ta strona wykorzystuje pliki cookie</b><br/>
          Używamy informacji zapisanych za pomocą plików cookies w celu zapewnienia maksymalnej wygody w korzystaniu z naszego serwisu.
          Mogą też korzystać z nich współpracujące z nami firmy badawcze oraz reklamowe. Jeżeli wyrażasz zgodę na zapisywanie informacji
          zawartej w cookies kliknij na „OK”.
          Jeśli nie wyrażasz zgody, ustawienia dotyczące plików cookies możesz zmienić w swojej przeglądarce.
        </div>
      </cookie-law>
    </footer>
  </v-app>
</template>

<script>

import SnackBar from "@/components/SnackBar";
import TopBar from "@/components/TopBar";
import LoaderOverlay from "@/components/LoaderOverlay";
import MainContainer from "@/components/MainContainer.vue";
import BottomBar from "@/components/Partials/BottomBar.vue";
import bannerMixin from "@/mixins/bannerMixin";
import imageMixin from "@/mixins/imageMixin";
import TopBarMobile from "@/components/TopBarMobile.vue";
import CookieLaw from 'vue-cookie-law';

export default {
  name: 'App',
  mixins: [bannerMixin, imageMixin],
  components: {TopBarMobile, BottomBar, MainContainer, LoaderOverlay, TopBar, SnackBar, CookieLaw },
  data: () => ({
    banners: [],
    randomBanner: null,
  }),
  mounted() {
    this.getBannersToShow(3);
  },
};
</script>

<style scoped>
.background-image {
  z-index: 0;
  position:fixed;
  width: 100%;
}
</style>