<template>
  <v-row justify="space-around">
    <v-col cols="12">
      <BasketContent :hide-buttons="true"/>
    </v-col>
    <v-col cols="12">
      <v-row justify="start">
        <v-col cols="5" v-html="orderText.deliveryType"/>
        <v-col cols="5" v-html="orderText.deliveryPrice"/>
        <v-col cols="2">{{ $store.state.order.delivery.gross }} {{ currency }}</v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row justify="start">
        <v-col cols="5" v-html="orderText.paymentType"/>
        <v-col cols="7" v-html="$store.state.order.payment ? $store.state.order.payment.name : ''"/>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="$store.state.order.delivery">
      <v-row justify="start">
        <v-col cols="12">
          <v-divider/>
        </v-col>
        <v-col cols="5" class="text-h6" v-html="orderText.totalPrice"/>
        <v-col cols="7" class="text-h6">{{ ($store.state.order.delivery.gross + basketValue).toFixed(2) }} {{ currency }}</v-col>
      </v-row>
    </v-col>
    <v-col cols="12" v-if="$store.state.order.delivery">
      <v-row justify="start">
        <v-col cols="12">
          <v-divider/>
        </v-col>
        <v-col cols="12" class="text-h6">
          <v-checkbox
              v-model="rulesConfirmed"
              :label="orderText.confirmRules"
          ></v-checkbox>
          <v-checkbox v-model="rulesPrivacy">
            <template v-slot:label>
              <div v-html="orderText.confirmPrivacy" @click.stop></div>
            </template>
          </v-checkbox>
          <v-checkbox v-if="showPrzelewy24Confirmation"
                      v-model="rulesPrzelewyConfirmed">
            <template v-slot:label>
              <div v-html="orderText.confirmRulesPrzelewy24" @click.stop></div>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row justify="space-around">
        <v-col cols="12" sm="3">
          <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="$emit('prevStep')"
          >
            <v-icon
                right
                dark
                class="mr-1"
            >
              mdi-arrow-left-bold-outline
            </v-icon>
            {{ previousStep }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          <v-btn
              color="primary"
              class="ma-2 white--text"
              @click="placeOrder"
              :loading="processingOrder"
              :disabled="!rulesPrivacy || ($store.state.order.payment && this.$store.state.order.payment.id === 1) ? !rulesConfirmed || !rulesPrzelewyConfirmed : !rulesConfirmed"
          >
            {{ orderText.confirmBuy }}
            <v-icon
                right
                dark
            >
              mdi-cash
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import BasketContent from "@/components/Partials/BasketContent.vue";
import basketMixin from "@/mixins/basketMixin";

export default {
  name: "PlaceOrderSummary",
  components: {BasketContent},
  mixins: [translationMixin, basketMixin],
  data: () => ({
    rulesConfirmed: false,
    rulesPrivacy: false,
    rulesPrzelewyConfirmed: false,
    processingOrder: false,
  }),
  computed: {
    showPrzelewy24Confirmation() {
      return this.$store.state.order.payment && this.$store.state.order.payment.id === 1;
    },
    currency: function () {
      return this.getTranslationByKey('app.currency');
    },
    previousStep: function () {
      return this.getTranslationByKey('basket.previousStep');
    },
    orderText: function () {
      return this.getTranslationByKey('order');
    },
    orderSummaryPrice: function () {
      return this.$store.state.order.delivery.gross + this.basketValue;
    }
  },
  methods: {
    placeOrder() {
      this.processingOrder = true;
      const order = Object.assign(this.$store.state.order, {basket: this.$store.state.basket});
      this.ax.makeCall('POST', 'orders', order)
          .then((response) => {
            this.processingOrder = false;
            this.clearBasket();
            if (response.przelewy24Error) {
              this.showSuccess(response.przelewy24Error);
            } else if (response.przelewy24Url) {
              window.location.replace(response.przelewy24Url);
            } else {
              this.showSuccess(response.message);
            }
          })
          .catch((error) => {
            this.showError(error);
            this.processingOrder = false;
          });
    },
    closeBasket() {
      this.$router.push({name: 'home'});
    },
    clearBasket() {
      this.$store.commit('setStateParam', {
        name: 'basket',
        value: [],
      })
      sessionStorage.removeItem('basket');
      this.closeBasket();
    }
  }
}
</script>

<style scoped>

</style>