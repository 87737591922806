<template>
  <div>
    <v-card-text>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="form.email"
            :rules="emailRules"
            :label="emailInput"
        />
        <v-text-field
            v-model="form.email2"
            :rules="repeatEmail"
            :label="repeatEmailInput"
        />
        <v-text-field
            v-model="form.password"
            :rules="passwordRules"
            :label="passwordInput"
            :type="'password'"
        />
        <v-text-field
            v-model="form.password2"
            :rules="passwordRepeatRules"
            :label="repeatPasswordInput"
            :type="'password'"
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
          class="ma-2"
          :color="colorSet.norbitPrimary"
          tile
          v-html="buttonRegister"
          @click="recaptcha"
          :disabled="!valid"
      />
      <v-btn
          class="ma-2"
          outlined
          :color="colorSet.norbitPrimary"
          tile
          v-html="buttonCancel"
          @click="$emit('closeForm')"
      />
    </v-card-actions>
  </div>
</template>

<script>
import loginRegisterResetMixin from "@/mixins/loginRegisterResetMixin";
import translationMixin from "@/mixins/translationMixin";

export default {
  name: "RegisterForm",
  mixins: [translationMixin, loginRegisterResetMixin],
  data: () => ({
    valid: true,
    recaptchaToken: null,
    form: {
      email: null,
      email2: null,
      password: null,
      password2: null,
    }
  }),
  mounted() {
    this.validate();
  },
  methods: {
    async recaptcha() {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login');
      if (token) {
        this.form.recaptchaToken = token;
        this.registerUser();
      }
    },
    registerUser() {
      this.showLoader();
      this.$emit('closeForm');
      this.ax.makeCall('POST', 'customer/register', this.form)
          .then((response) => {
            this.hideLoader();
            this.showSuccess(response.message);
          })
          .catch((response) => {
            this.hideLoader();
            this.showError(response.message);
          });
    }
  }
}
</script>

<style scoped>

</style>