<template>
  <v-card flat tile color="#f7f7f7">
    <v-row no-gutters>
      <v-col cols="6" class="pa-1 cursor-pointer" @click="openProductPage" style="height: 185px">
        <v-img :src="prepareImageUrl(product.image)" width="100%" @error="noImage" v-if="!showSkeleton"/>
        <v-skeleton-loader v-else width="100%" max-height="185" type="image"></v-skeleton-loader>
      </v-col>
      <v-col cols="6" class="pa-1 position-relative">
        <ProductPricePart :product="product"/>
      </v-col>
    </v-row>
    <v-row no-gutters class="py-0 preorder-date-part">
      <v-col class="py-0 text-center"
             :style="{
              color: colorSet.norbitPrimary,
             }">
        <template v-if="product.end_preorder_date">({{premiereText}}: {{ product.end_preorder_date }})</template>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" v-html="product.title" class="text-center mt-2 product-title cursor-pointer"
             :style="{
              color: colorSet.norbitPrimary,
             }"
             @click="openProductPage"
      />
      <ProductBasketButton :product="product"
                           :platform="this.product.platform.slugged_name"
                           :category="this.product.category.slugged_name"
      />
    </v-row>
  </v-card>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import imageMixin from "@/mixins/imageMixin";
import ProductPricePart from "@/components/Partials/ProductPricePart.vue";
import ProductBasketButton from "@/components/Partials/ProductBasketButton.vue";

export default {
  name: "ProductTile",
  components: {ProductBasketButton, ProductPricePart},
  props: ['product'],
  mixins: [translationMixin, imageMixin],
  data: () => ({
    showSkeleton: false,
  }),
  methods: {
    noImage() {
      this.showSkeleton = true;
    },
    openProductPage() {
      window.scrollTo(0,0);
      const currentCategory = this.$route.params.category;
      const currentPlatform = this.$route.params.platform;
      const currentProductTitle = this.$route.params.productTitle;
      const newCategory = this.product.category.slugged_name;
      const newPlatform = this.product.platform.slugged_name;
      const newProductTitle = this.product.slugged_name;
      if (currentCategory !== newCategory
          || currentPlatform !== newPlatform
          || currentProductTitle !== newProductTitle
      ) {
        this.$router.push({
          name: 'productPage',
          params: {
            category: this.product.category.slugged_name,
            platform: this.product.platform.slugged_name,
            productTitle: this.product.slugged_name
          }
        })
      }
    }
  },
  computed: {
    premiereText() {
      return this.getTranslationByKey('productModule.premiere');
    }
  }
}
</script>

<style scoped>

.product-title {
  font-size: 16px;
  height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* after 3 line show ... */
  -webkit-box-orient: vertical;
}

.preorder-date-part {
  height: 15px;
}
</style>