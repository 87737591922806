<template>
  <div class="pa-0">
    <v-app-bar
        app
        height="60"
        flat
        :color="colorSet.norbitPrimary"
    >
      <v-container class="width-1180 pa-0" v-if="loadedTranslations">
        <v-row>
          <v-col cols="2" align-self="center">
            <v-img :src="logo" alt="NORBiT"
                   class="cursor-pointer"
                   @click="goToHomePage"
                   max-width="189"
            />
          </v-col>
          <v-col cols="5" align-self="center">
            <v-row no-gutters justify="space-around">
              <v-col cols="2" v-for="(item, index) in menu" :key="'top_menu_'+index">
                <div
                    class="top-menu position-relative"
                >
                  <div @click="clickOnMenu(item)" class="cursor-pointer">{{ item.name }}</div>
                  <v-icon class="top-menu-icon"
                          :color="item.id===selectedMenu ? colorSet.norbitPrimary : colorSet.norbitSecondary"
                  >mdi-menu-down
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="pt-6">
            <v-row no-gutters class="pa-0">
              <SearchField/>
              <v-col cols="6">
                <v-row no-gutters>
                  <TopLogin/>
                  <TopBasket/>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-toolbar
        class="mt-15 lower-bar"
        height="30"
        :color="colorSet.norbitSecondary"
    >
      <transition name="fade">
        <v-row no-gutters justify="center" v-if="subMenu.length > 0" class="width-1180 mx-auto">

          <v-col cols="2" v-for="(item, index) in subMenu" :key="'sub_menu_'+index" class="cursor-pointer"
                 @click="clickOnMenu(item)">
            <span v-html="item.name" class="top-menu"/>
          </v-col>

        </v-row>
      </transition>
    </v-toolbar>
  </div>
</template>

<script>
import logo from '@/assets/img/norbit-logo-white.svg'
import translationMixin from "@/mixins/translationMixin";
import menuMixin from "@/mixins/menuMixin";
import SearchField from "@/components/Partials/SearchField.vue";
import TopLogin from "@/components/Partials/TopLogin.vue";
import TopBasket from "@/components/Partials/TopBasket.vue";

export default {
  name: "TopBar",
  components: {TopBasket, TopLogin, SearchField},
  mixins: [translationMixin, menuMixin],
  data: () => ({
    logo,
    menu: [],
    subMenu: [],
    selectedMenu: 0,
  }),
  mounted() {
    this.getMenuByPosition(1, 'menu');
  },
  methods: {
    goToHomePage() {
      if (this.$route.name !== 'home') {
        this.$router.push({name: 'home'})
      }
    },
    clickOnMenu(item) {
      this.selectedMenu = item.id;
      if (item.sub_menu.length === 0) {
        this.subMenu = [];
        if (this.$route.path !== item.url) {
          this.$router.push({path: item.url});
        }
      } else {
        this.subMenu = item.sub_menu;
      }
    }
  }
}
</script>

<style scoped>
.lower-bar {
  position: fixed;
  width: 100%;
  z-index: 2;
}

.top-menu {
  color: #ffffff;
}

.top-menu-icon {
  position: absolute;
  left: 30%;
  bottom: -32px;
}
</style>