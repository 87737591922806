<template>
  <v-row no-gutters dense>
    <script type="application/javascript">
      function afterPointSelected(point) {
        var myEvent = new CustomEvent("myCustomEvent", {
          detail: point,
        });
        document.dispatchEvent(myEvent);
      }
    </script>
    <v-col cols="12">
      <v-radio-group
          v-model="deliveryType"
      >
        <v-radio
            v-for="(delivery, index) in deliveries" :key="'delivery_type'+index"
            :label="delivery.name + ' (' + delivery.gross + currency + ')'"
            :value="delivery.id"
            @click="updateOrderDelivery"
        ></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="12" v-if="deliveryType===1">
      {{ inpostSelectedTxt }} {{ inpostData ? inpostData.name : '' }}
    </v-col>
    <v-col cols="12" v-if="deliveryType===1" class="inpost-widget">
      <inpost-geowidget id='3' onpoint="afterPointSelected"
                        token='eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJzQlpXVzFNZzVlQnpDYU1XU3JvTlBjRWFveFpXcW9Ua2FuZVB3X291LWxvIn0.eyJleHAiOjIwMTU5MzEyNDQsImlhdCI6MTcwMDU3MTI0NCwianRpIjoiZmIwNDY4MmYtMjJiNS00ZmI5LTk5NTUtOGNkOWE3YTIwYzI3IiwiaXNzIjoiaHR0cHM6Ly9sb2dpbi5pbnBvc3QucGwvYXV0aC9yZWFsbXMvZXh0ZXJuYWwiLCJzdWIiOiJmOjEyNDc1MDUxLTFjMDMtNGU1OS1iYTBjLTJiNDU2OTVlZjUzNTpJM2djcXlMMW1Sb3RRNWhyR2dwZjhMejlvSWdmSk9tOE9VYS1RRXVWYl9BIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoic2hpcHgiLCJzZXNzaW9uX3N0YXRlIjoiYzU4NTY5ZmMtZjY2Yi00OTg5LWEwYmYtNWJhYmIxODcyYTk4Iiwic2NvcGUiOiJvcGVuaWQgYXBpOmFwaXBvaW50cyIsInNpZCI6ImM1ODU2OWZjLWY2NmItNDk4OS1hMGJmLTViYWJiMTg3MmE5OCIsImFsbG93ZWRfcmVmZXJyZXJzIjoiKi5ub3JiaXQucGwiLCJ1dWlkIjoiYTQwMTRkODctZDliOS00NjAyLWFiYjUtZTdlZTQzM2E3ODJiIn0.oiKUEV2FAUHAd1ilijy62qgvjP5UGM8J3iYdA_KI2CxbSzA0EkDoVzRCyoabbPFsrw8lGG1UMe0D8hYYqAtn1Hs-BzxoW67PGRt1PKdrE4REWPnot7tRRjVTGghbmAqWY_nHmP-iYVmmjEJRy-VvKPvHLUt9CXhPHZgfrKhXrF2f31B3HAibuuDEtV2hSWL4_F-8V3j8gJu8IQWlSRY80eMnQrP6r_GWlm4WVCPmwTX8JU52ISCs2ph9jo5X8es5vjbHL_1D6aiSs2ahg0aYdufljeXZWLTey9d0Ha1i4z9cIEm5lDLeqn67NeBRUCGHIvcSxxcYXgR2hrJs7gMYcw'
                        language='pl' config='parcelCollect'></inpost-geowidget>
    </v-col>
    <PlaceOrderNextPrevButtons :show-prev="true" :show-next="!!deliveryType"
                               v-on:prevStep="$emit('prevStep')"
                               v-on:nextStep="$emit('nextStep')"
    />
  </v-row>
</template>

<script>
import translationMixin from "@/mixins/translationMixin";
import PlaceOrderNextPrevButtons from "@/components/Partials/PlaceOrderNextPrevButtons.vue";

export default {
  name: "PlaceOrderDelivery",
  components: {PlaceOrderNextPrevButtons},
  mixins: [translationMixin],
  data: () => ({
    deliveryType: null,
    deliveries: [],
    inpostData: null,
  }),
  metaInfo() {
    return {
      link: [
        {
          rel: "stylesheet",
          href: "https://geowidget.inpost.pl/inpost-geowidget.css"
        }
      ],
      script: [
        {src: 'https://geowidget.inpost.pl/inpost-geowidget.js', async: true, defer: true}
      ],
    }
  },
  mounted() {
    this.getDeliveries();
    document.addEventListener("myCustomEvent", (event) => {
      this.inpostData = event.detail;
      this.updateOrderDelivery();
    });
  },
  methods: {
    updateOrderDelivery() {
      const selectedDelivery = this.deliveries.filter(delivery => delivery.id === this.deliveryType);
      if (selectedDelivery.length > 0) {
        let order = this.$store.state.order;
        if (order) {
          if (this.inpostData && this.deliveryType === 1) {
            selectedDelivery[0].description = this.inpostData.name + ', ' + this.inpostData.address.line1 + ', ' + this.inpostData.address.line2;
          }
          order = Object.assign(order, {delivery: selectedDelivery[0]});
        } else {
          order = {delivery: selectedDelivery[0]};
        }
        this.$store.commit('setStateParam', {
          name: 'order',
          value: order
        })
      }
    },
    getDeliveries() {
      this.ax.makeCall('GET', 'deliveries')
          .then((response) => {
            this.deliveries = response;
          });
    }
  },
  computed: {
    currency: function () {
      return this.getTranslationByKey('app.currency');
    },
    inpostSelectedTxt: function () {
      return this.getTranslationByKey('order.inpostSelected');
    },
  }
}
</script>

<style scoped>
.inpost-widget {
  height: 500px;
}
</style>